import { AppHeader, Avatar, IconButton } from '@nike/eds'
import { NikeApp } from '@nike/nike-design-system-icons'
import { useOktaAuth } from '@okta/okta-react'
import { Link } from 'react-router-dom'
import { useMemo } from 'react'

export function Header() {
    const {REACT_APP_SPLUNK_DASHBOARD: splunkDashboard} = process.env
    const { authState } = useOktaAuth()
    const initials = useMemo(() => {
        const name = authState?.accessToken?.claims?.sub
        return name ? getInitials(name) : '-'
    }, [authState])

    return (
        <AppHeader
            isDark
            appName='Label & Print Manager'
            logoSlot={
                <Link reloadDocument to='/' aria-label='logo-link'>
                    <NikeApp
                        title='Nike logo'
                        color={'white'}
                        width={'56px'}
                        height={'64px'}
                        style={{ marginTop: '6px' }}
                    />
                </Link>
            }
            toolbarSlot={
                <div>
                    <IconButton reloadDocument as={Link} to='/request-overview' label='Request overview' icon='Menu' variant='ghost' />
                    <IconButton reloadDocument as={Link} to='/printer-management' label='Printer management' icon='Print' variant='ghost' />
                    <IconButton as="a" href={splunkDashboard} target={"_blank"} label='Dashboard' icon='Activity' variant='ghost' />
                    <IconButton reloadDocument as={Link} to='/simulation/simulate' label='Simulate' icon='Play' variant='ghost' />
                </div>
            }
            avatarSlot={<Avatar initials={initials} size={36} />}
        />
    )
}

function getInitials(name: string) {
    const matchedFirstLetters = name.match(/(\b[A-Z])/g)
    if (!matchedFirstLetters || matchedFirstLetters.length < 2) {
        // Fallback: take first letter
        return name[0]?.toUpperCase()
    }
    return `${matchedFirstLetters.shift()}${matchedFirstLetters.pop()}`
}
