import MicroFrontend from '../MicroFrontend'
import {Header} from '../shared/header/Header'
import {Footer} from '../shared/footer/Footer'

export function RequestOverview({history}) {
    const {REACT_APP_REQUEST_OVERVIEW_HOST: requestOverviewHost} = process.env
    return (
        <div>
            <Header/>
            <MicroFrontend
                history={history}
                host={requestOverviewHost}
                microFrontendUiName="RequestOverview"/>
            <Footer/>
        </div>
    )
}
