import MicroFrontend from '../MicroFrontend'
import {Header} from '../shared/header/Header'

export function Simulation({history}) {
    const {REACT_APP_SIMULATION_HOST: simulationHost} = process.env
    return (
        <div className="setHeightSideBar"> {/*Force full height because otherwise the SideBar doesnt fill full height*/}
            <Header/>
            <MicroFrontend
                history={history}
                host={simulationHost}
                microFrontendUiName="Simulation"/>
        </div>
    )
}
