import 'normalize.css' //Keep this first!
import './styles/Fonts.css'
import './styles/Index.css'
import '@nike/eds/dist/index.css'
import React from 'react'
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'

import App from './App'

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
)
