import '@nike/eds/dist/index.css'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { Security, LoginCallback } from '@okta/okta-react'
import React, { useCallback } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { oktaAuth } from './config/OktaConfig'
import { LoginError } from './login-error/LoginError'
import { RequiredAuth } from './shared/AuthenticatedRoute'
import { RequestOverview } from './pages/RequestOverview'
import { Simulation } from './pages/Simulation'
import { createBrowserHistory } from 'history'
import {PrinterManagement} from "./pages/PrinterManagement";

const history = createBrowserHistory()

function App() {
    const [authenticated, setAuthenticated] = React.useState(false);
    const navigate = useNavigate()
    const restoreOriginalUri = useCallback(
        (_oktaAuth: any, originalUri: string) => {
            navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true })
        },
        [navigate]
    )

    oktaAuth.authStateManager.subscribe((authState) => {
        setAuthenticated(authState?.isAuthenticated)
    });

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <React.Fragment>
                <Routes>
                    <Route element={<RequiredAuth/>}>
                        <Route path="/simulation/*" element={authenticated && <Simulation history={history}/>}/>
                        <Route path="/printer-management/*" element={authenticated && <PrinterManagement history={history}/>}/>
                        <Route path="/request-overview/*" element={authenticated && <RequestOverview history={history}/>}/>
                        <Route path="/" element={<Navigate to="/request-overview"/>}/>
                    </Route>
                    <Route path="/implicit/callback" element={<LoginCallback errorComponent={LoginError}/>}/>
                </Routes>
            </React.Fragment>
        </Security>
    );
}

export default App
