import MicroFrontend from '../MicroFrontend'
import {Header} from '../shared/header/Header'

export function PrinterManagement({history}) {
    const {REACT_APP_PRINTER_MANAGEMENT_HOST: printerManagementHost} = process.env
    return (
        <div className="setHeightSideBar"> {/*Force full height because otherwise the SideBar doesnt fill full height*/}
            <Header/>
            <MicroFrontend
                history={history}
                host={printerManagementHost}
                microFrontendUiName="PrinterManagement"/>
            {/*<Footer /> No footer because it clashes with the left navigation bar*/}
        </div>
    )
}
