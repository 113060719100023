import React, {useEffect} from "react";

function MicroFrontend({history, host, microFrontendUiName}) {
    useEffect(() => {
        const scriptId = `micro-frontend-script-${microFrontendUiName}`;

        const renderMicroFrontend = () => {
            window[`render${microFrontendUiName}`](`${microFrontendUiName}-container`, history);
        };

        if (document.getElementById(scriptId)) {
            renderMicroFrontend();
            return () => {
                window[`unmount${microFrontendUiName}`] && window[`unmount${microFrontendUiName}`](`${microFrontendUiName}-container`);
            };
        }

        fetch(`${host}/asset-manifest.json`)
            .then((res) => res.json())
            .then((manifest) => {
                const css  = document.createElement('link');
                css.id   = scriptId + "css";
                css.rel  = 'stylesheet';
                css.type = 'text/css';
                css.href = `${host}${manifest.files["main.css"]}`;
                css.media = 'all';
                document.head.appendChild(css);

                const script = document.createElement("script");
                script.id = scriptId;
                script.crossOrigin = "";
                script.src = `${host}${manifest.files["main.js"]}`;
                script.onload = () => {
                    renderMicroFrontend();
                };
                document.head.appendChild(script);
            });
        return () => {
            window[`unmount${microFrontendUiName}`] && window[`unmount${microFrontendUiName}`](`${microFrontendUiName}-container`);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <div className="fillHeight" id={`${microFrontendUiName}-container`}/>;
}

MicroFrontend.defaultProps = {
    document,
    window,
};

export default MicroFrontend;
